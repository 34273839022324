<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  mounted() {
    console.log(this.items)
  }
}
</script>

<template>
  <!-- start page title -->
  <!-- <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h3 class="mb-0">{{ title }}</h3>

        <div class="page-title-right font-size-15">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div> -->
  <div class="position-fixed" style="z-index:11; width:78%; top:70px;">
    <div class="card row">
      <div class="page-title-right font-size-15">
        <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
      </div>
      <!-- <div style="display: flex;">
  <router-link v-for="item in items" :key="item.id" :to="`${item.url}`" class="text-muted">
    <span>{{ item.name }}</span>
  </router-link>
</div> -->
    </div>
  </div>
<br>
<br>
<br>

  <!-- end page title -->
</template>
